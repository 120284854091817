import React, { useCallback, useMemo, useState } from 'react';
import cl from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import { ItemMessagesListMessageImagesMessage } from './ItemMessagesListMessageImages.types';
import {
  FetchMessagesCacheKey,
  MessageAttachmentTypes,
  MessageFinAttachmentTypes,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  OnEditMessageAction
} from '../../../../../messagesTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { FileAttachmentItemImageVersions } from '../../../../../../../helpers/ImageHelper';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';
import { ModelLightboxTitleAttachment } from '../../../../../../common/helpers/ModelLightboxTitle/ModelLightboxTitle';

import { useImageEditorSubmitWithItemNanoId } from '../../../../../../../helpers/ImageEditor/hooks/useImageEditorSubmitWithItemNanoId';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useDownloadTypeFile } from '../../../../../../s3Multipart/hooks/useDownloadTypeFile';

import {
  ItemMessagesListMessageImage,
  mapMessageAttachmentImage
} from '../ItemMessagesListMessageImage';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { SendImagesToWhiteboardPageDynamicModalButton } from '../../../../../../whiteboards/components/buttons/SendImagesToWhiteboardPageModalButton';

import {
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle,
  LightboxThumbnails,
  LightboxWrapper,
  OnLightboxImageDownload,
  useLightboxWrapper
} from '../../../../../../../helpers/LightboxWrapper';
import { ModelLightboxTitle } from '../../../../../../common/helpers/ModelLightboxTitle';
import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { Files } from '../../../../../../../utils/Files';
import { sortMessageFileAttachments } from '../../../../../utils/sortMessageFileAttachments';
import { getFileAttachmentName } from '../../../../../../fileAttachments/utils/getFileAttachmentName';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { LightboxWrapperPermissions } from '../../../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { stringsKeys } from '../../../../../../../locales/keys';

type ItemMessagesListMessageImagesProps = {
  message: ItemMessagesListMessageImagesMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  withEditMessage?: boolean;
  withoutDropdownMenu?: boolean;
  onEditMessage?: OnEditMessageAction;
} & CheckableMessagesProps;

function ItemMessagesListMessageImages({
  message,
  messagesCacheKey,
  onReplyMessage,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose,
  withEditMessage,
  withoutDropdownMenu,
  onEditMessage,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageImagesProps) {
  const currentUser = useCurrentUser();

  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const attachments = useMemo(() => {
    const fileAttachments = sortMessageFileAttachments(
      message.fileAttachments?.filter((fileAttachment) =>
        Files.isImage(fileAttachment.file)
      )
    );

    const fileAttachmentsWithOriginalName = fileAttachments.map(
      (fileAttachment) => ({
        ...fileAttachment,
        name: getFileAttachmentName(fileAttachment)
      })
    );

    const objectAttachments = sortBy(
      [
        ...(message?.selectedProducts || []),
        ...(message?.selectedLifestyles || []),
        ...(message?.selectedMaterials || [])
      ],
      ['createdAt']
    );

    return [...fileAttachmentsWithOriginalName, ...objectAttachments];
  }, [
    message.fileAttachments,
    message.selectedLifestyles,
    message.selectedMaterials,
    message.selectedProducts
  ]);

  const attachmentsInLightbox = useMemo(() => {
    return attachments.filter((attachment) => {
      const lightboxImage = mapMessageAttachmentImage(attachment);
      return lightboxImage && Files.isImage(lightboxImage?.file);
    });
  }, [attachments]);

  const lightboxItems = useMemo(() => {
    return attachmentsInLightbox.map(mapMessageAttachmentImage);
  }, [attachmentsInLightbox]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return attachmentsInLightbox.map((attachment) =>
      attachment.type === MessageAttachmentTypes.FILE_ATTACHMENT
        ? Files.urlFromFile(
            attachment.file,
            FileAttachmentItemImageVersions.FullScreenThumb1000x850
          )
        : null
    );
  }, [attachmentsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: lightboxItems,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null,
    toggleBackdrop: togglePreventModalClose
  });

  const projectNanoId = message.project?.nanoId;

  const withSendToWhiteboardButton =
    imageItem?.uuid &&
    get(imageItem, 'type') === MessageAttachmentTypes.FILE_ATTACHMENT &&
    (projectNanoId || message.task?.nanoId) &&
    currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_SEND_IMAGE_TO_WHITEBOARD_BUTTON
    );

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      if (!withSendToWhiteboardButton) {
        return [];
      }

      const images = [
        {
          fileUrl: imageItem?.file,
          height: imageItem?.height,
          name: imageItem?.name,
          width: imageItem?.width,
          fileAttachmentId: get(imageItem, 'id') || imageItem?.uuid
        }
      ];

      return (
        <SendImagesToWhiteboardPageDynamicModalButton
          addDialogClassName="z-9999"
          addModalClassName="z-9999"
          cacheKeys={[messagesCacheKey]}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
          icon={IconsEnum.DRAW_CURVE_OUTLINE}
          iconClassName="h-6 w-6"
          images={images}
          isProjectWhiteboard={!!projectNanoId}
          taskNanoId={message.task?.nanoId}
          projectNanoId={projectNanoId || message.task?.project?.nanoId}
          tooltipI18nText={stringsKeys.sendToWhiteboard}
          tooltipPlacement={TooltipPlacement.TOP}
        />
      );
    },
    [
      imageItem,
      message.task?.nanoId,
      message.task?.project?.nanoId,
      messagesCacheKey,
      projectNanoId,
      withSendToWhiteboardButton
    ]
  );

  const { onDownload } = useDownloadTypeFile({});

  const handleDownload = useCallback<OnLightboxImageDownload>(
    (uuid) => {
      const item = attachments?.find((item) => {
        if (
          item.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
          item.type === MessageFinAttachmentTypes.FILE_ATTACHMENT
        ) {
          return item.uuid === uuid;
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
          item.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE
        ) {
          return item.lifestyle.image.uuid === uuid;
        }

        if (
          item.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
          item.type === MessageFinAttachmentTypes.SELECTED_PRODUCT
        ) {
          return item.product.image.uuid === uuid;
        }

        if (
          item.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
          item.type === MessageFinAttachmentTypes.SELECTED_MATERIAL
        ) {
          return item.material.image.uuid === uuid;
        }
      });

      if (item) {
        if (
          item.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
          item.type === MessageFinAttachmentTypes.FILE_ATTACHMENT
        ) {
          onDownload(item.uuid, 'fileAttachments');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
          item.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE
        ) {
          onDownload(item.lifestyle.uuid, 'lifestyles');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
          item.type === MessageFinAttachmentTypes.SELECTED_PRODUCT
        ) {
          onDownload(item.product.uuid, 'products');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
          item.type === MessageFinAttachmentTypes.SELECTED_MATERIAL
        ) {
          onDownload(item.material.uuid, 'materials');
        }
      }
    },
    [attachments, onDownload]
  );

  const { handleImageEditorSubmitWithItemNanoId } =
    useImageEditorSubmitWithItemNanoId(
      message.project?.nanoId
        ? { projectNanoId: message.project.nanoId }
        : { taskNanoId: message.task?.nanoId }
    );

  const currentLightboxImageAttachment = attachmentsInLightbox[index];

  const attachmentsTotalCount = size(attachments);

  const isVisibleForClient = !message.body && message.visibleForClient;

  const withMenu =
    isEmpty(message.body) &&
    isEmpty(message.todoItems) &&
    isEmpty(message.forwardedMessage?.body);

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(() => {
    if (!currentLightboxImageAttachment) {
      return null;
    }

    if (
      currentLightboxImageAttachment.type !==
        MessageAttachmentTypes.FILE_ATTACHMENT &&
      currentLightboxImageAttachment.type !==
        MessageFinAttachmentTypes.FILE_ATTACHMENT
    ) {
      return (
        <ModelLightboxTitle
          attachment={
            currentLightboxImageAttachment as ModelLightboxTitleAttachment
          }
        />
      );
    }
  }, [currentLightboxImageAttachment]);

  if (attachmentsTotalCount === 0) {
    return null;
  }

  return (
    <>
      <div
        className={cl('mt-1 flex', {
          'justify-end': reverse,
          'mb-0.5': attachmentsTotalCount > 0
        })}
      >
        <div
          ref={setReferenceTooltipElement}
          className={cl('rounded-xl max-w-xl', {
            'rounded-tl': message.body && !reverse,
            'rounded-bl': attachmentsTotalCount > 0 && !reverse,
            'rounded-tr': message.body && reverse,
            'rounded-br': attachmentsTotalCount > 0 && reverse,
            'grid gap-1 w-full': attachmentsTotalCount > 1,
            'grid-cols-2':
              attachmentsTotalCount === 2 || attachmentsTotalCount === 4,
            'grid-cols-2 sm:grid-cols-3':
              attachmentsTotalCount === 3 ||
              attachmentsTotalCount === 5 ||
              attachmentsTotalCount === 6,
            'grid-cols-2 sm:grid-cols-4': attachmentsTotalCount > 6
          })}
        >
          {attachments.map((attachment) => (
            <ItemMessagesListMessageImage
              message={message}
              messagesCacheKey={messagesCacheKey}
              key={attachment.uuid}
              attachment={attachment}
              onLightboxOpen={handleLightboxOpenOnSlide}
              attachmentsTotalCount={attachmentsTotalCount}
              isVisibleForClient={isVisibleForClient}
              togglePreventModalClose={togglePreventModalClose}
            />
          ))}
        </div>
        <TooltipMessageDate
          referenceElement={referenceTooltipElement}
          date={message.createdAt}
        />
        {withMenu && (
          <ItemMessagesListMessageMenu
            message={message}
            messagesCacheKey={messagesCacheKey}
            onReplyMessage={onReplyMessage}
            forwardMessageProjectUuid={forwardMessageProjectUuid}
            forwardMessageTaskUuid={forwardMessageTaskUuid}
            withEditMessage={withEditMessage}
            withoutDropdownMenu={withoutDropdownMenu}
            onEditMessage={onEditMessage}
            checkedMessages={checkedMessages}
            onSetCheckedMessage={onSetCheckedMessage}
            isLeft={reverse}
          />
        )}
      </div>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        mainSrcThumbnail={mainSrcThumbnail}
        nextSrcThumbnail={nextSrcThumbnail}
        prevSrcThumbnail={prevSrcThumbnail}
        withFullScreenButton
        renderCustomButtons={renderLightboxButtons}
        renderImageTitle={
          currentLightboxImageAttachment?.type !==
            MessageAttachmentTypes.FILE_ATTACHMENT &&
          currentLightboxImageAttachment?.type !==
            MessageFinAttachmentTypes.FILE_ATTACHMENT
            ? renderImageTitle
            : undefined
        }
        withBaseImageTitle={
          currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
          currentLightboxImageAttachment?.type ===
            MessageFinAttachmentTypes.FILE_ATTACHMENT
        }
        toggleImageEditorBackdrop={togglePreventModalClose}
        onImageEditSubmit={
          (currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
            currentLightboxImageAttachment?.type ===
              MessageFinAttachmentTypes.FILE_ATTACHMENT) &&
          currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
          )
            ? handleImageEditorSubmitWithItemNanoId
            : undefined
        }
        onImageDownload={handleDownload}
      />
    </>
  );
}

export default ItemMessagesListMessageImages;
