import React, { Fragment, useCallback, useState } from 'react';
import keys from 'lodash/keys';
import values from 'lodash/values';
import sizeOf from 'lodash/size';
import sum from 'lodash/sum';

import { DropzoneAreaFile } from '../../../../../../../../../helpers/dropzone/DropzoneArea';

import { Tooltip } from '../../../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import { ProgressCircleHelper } from '../../../../../../../../../helpers/ProgressCircleHelper';

import { getFileIcon } from '../../../../../../../../../utils/getFileIcon';
import { Files } from '../../../../../../../../../utils/Files';
import { getFileAttachmentName } from '../../../../../../../../fileAttachments/utils/getFileAttachmentName';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface ItemCreateMessageAttachmentsListUploadingFileProps {
  id: string;
  fileAttachment: DropzoneAreaFile;
  removeFile: (id: string) => void;
}

function ItemCreateMessageAttachmentsListUploadingFile({
  id,
  fileAttachment,
  removeFile
}: ItemCreateMessageAttachmentsListUploadingFileProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const progress = Math.round(
    sum(values(fileAttachment.progresses)) /
      (sizeOf(keys(fileAttachment.progresses)) || 1)
  );

  const handleRemoveFile = useCallback(() => removeFile(id), [id, removeFile]);

  return (
    <div
      className={
        Files.isImage(fileAttachment.name)
          ? 'h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item w-16'
          : 'h-16 min-w-12 relative rounded bg-gray-100 dark:bg-gray-850 group/item max-w-48 pl-2 pr-3.5 py-1.5 items-center flex border dark:border-gray-800'
      }
      ref={setReferenceTooltipElement}
    >
      {Files.isImage(fileAttachment.name) ? (
        <ImageHelper
          tooltipAlt
          className="w-full h-full rounded object-cover bg-gray-100 dark:bg-gray-850"
          src={fileAttachment.file}
          alt={getFileAttachmentName(fileAttachment)}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
        />
      ) : (
        <div className="absolute top-0 left-0 h-full flex items-center px-2">
          <Icon icon={getFileIcon(fileAttachment.file)} className="h-6 w-6" />
        </div>
      )}

      <div className="absolute inset-0 flex items-center justify-center px-2 group bg-slate-600/75 rounded text-gray-200 font-semibold">
        <div className="h-12 w-12 my-1 relative">
          <ProgressCircleHelper
            color={fileAttachment.state === 'failed' ? 'red' : 'blue'}
            progress={progress > 99 ? 99 : progress}
          />

          <div className="flex items-center justify-center absolute inset-0 opacity-0 group-hover:opacity-100 focus-within:opacity-100">
            <PureIconButtonHelper
              className="dark:text-gray-200 bg-gray-200 focus:ring-offset-0 dark:bg-gray-800 text-gray-800 p-2 rounded-full focus:opacity-100"
              icon={IconsEnum.X}
              onClick={handleRemoveFile}
            />
          </div>
        </div>
      </div>

      {Files.isImage(fileAttachment.name) ? null : (
        <Fragment>
          <div className="line-clamp-3 text-sm break-words leading-tight pl-8 rounded pr-3.5">
            {getFileAttachmentName(fileAttachment)}
          </div>

          <Tooltip
            placement={TooltipPlacement.TOP}
            referenceElement={referenceTooltipElement}
            className="break-words"
            withArrow
          >
            <div className="break-words">
              {getFileAttachmentName(fileAttachment)}
            </div>
          </Tooltip>
        </Fragment>
      )}
    </div>
  );
}

export default ItemCreateMessageAttachmentsListUploadingFile;
