import { IconsEnum } from '../../../../../../../../assets/icons/types';
import { ItemMessagesListMessageFileFileAttachment } from '../ItemMessagesListMessageFile.types';

import { CheckPermissions } from '../../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../../helpers/Icon';
import { LinkHelper } from '../../../../../../../../helpers/links/LinkHelper';
import { SizeHelper } from '../../../../../../../../helpers/SizeHelper';
import { Translate } from '../../../../../../../../helpers/Translate';

import { getFileIcon } from '../../../../../../../../utils/getFileIcon';

import { words } from '../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../messagesConstants';
import { getFileAttachmentName } from '../../../../../../../fileAttachments/utils/getFileAttachmentName';

interface ItemMessagesListMessageFileBaseProps {
  fileAttachment: ItemMessagesListMessageFileFileAttachment;
  isVisibleForClient: boolean;
}

function ItemMessagesListMessageFileBase({
  fileAttachment,
  isVisibleForClient
}: ItemMessagesListMessageFileBaseProps) {
  return (
    <>
      <div className="flex flex-col justify-between items-center">
        <Icon
          icon={getFileIcon(fileAttachment.file)}
          className="h-5 w-5 -mt-0.5"
        />

        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON}
        >
          {isVisibleForClient && (
            <Icon
              icon={IconsEnum.EYE_SOLID}
              className="h-3 w-3 text-gray-500 mb-0.5 group-hover/item:hidden"
            />
          )}
        </CheckPermissions>

        <Icon
          icon={IconsEnum.DOWNLOAD_SOLID}
          className="h-3 w-3 text-gray-500 mb-0.5 hidden group-hover/item:block"
        />
      </div>

      <div className="flex flex-col justify-between gap-1.5">
        <LinkHelper
          className="break-all line-clamp-3 leading-4 text-xs font-medium focus:outline-none"
          href={fileAttachment.file}
          target="_blank"
        >
          {getFileAttachmentName(fileAttachment)}
          <span className="absolute inset-0" aria-hidden="true" />
        </LinkHelper>
        <div className="text-gray-600 dark:text-gray-400 text-xs relative pointer-events-none">
          <div className="group-hover/item:opacity-0">
            <SizeHelper size={fileAttachment.size} />
            {fileAttachment.ext ? (
              <span> &ndash; {fileAttachment.ext}</span>
            ) : null}
          </div>
          <div className="absolute inset-0 hidden group-hover/item:block">
            <Translate id={words.download} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemMessagesListMessageFileBase;
