import React, { useCallback, useState } from 'react';

import { FileAttachmentID } from '../../../../../../../../fileAttachments/fileAttachmentsTypes';
import { ItemCreateMessageAttachmentsListFileFileAttachment } from './ItemCreateMessageAttachmentsListFile.types';

import { Tooltip } from '../../../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';

import { getFileIcon } from '../../../../../../../../../utils/getFileIcon';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { getFileAttachmentName } from '../../../../../../../../fileAttachments/utils/getFileAttachmentName';

interface ItemCreateMessageAttachmentsListFileProps {
  fileAttachment: ItemCreateMessageAttachmentsListFileFileAttachment;
  removeFile: (id: FileAttachmentID) => void;
}

function ItemCreateMessageAttachmentsListFile({
  fileAttachment,
  removeFile
}: ItemCreateMessageAttachmentsListFileProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const handleRemoveFile = useCallback(
    () => removeFile(fileAttachment.id),
    [fileAttachment.id, removeFile]
  );

  return (
    <div
      className="h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item max-w-48 pl-2 pr-3.5 py-1.5 items-center flex border dark:border-gray-800"
      ref={setReferenceTooltipElement}
    >
      <PureIconButtonHelper
        className="absolute top-0 right-0 p-1 rounded-sm sm:opacity-0 group-hover/item:opacity-100 focus:opacity-100 bg-opacity-50 dark:hover:bg-opacity-100 hover:bg-opacity-100 text-gray-700 bg-white dark:bg-gray-900 dark:bg-opacity-50 dark:text-gray-300 focus:ring-offset-0"
        icon={IconsEnum.X}
        iconClassName="h-4 w-4"
        onClick={handleRemoveFile}
      />

      <div className="absolute top-0 left-0 h-full flex items-center px-2">
        <Icon icon={getFileIcon(fileAttachment.file)} className="h-6 w-6" />
      </div>

      <div className="line-clamp-3 text-sm break-words leading-tight pl-8 rounded pr-3.5">
        {getFileAttachmentName(fileAttachment)}
      </div>

      <Tooltip
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        className="break-words"
        withArrow
      >
        <div className="break-words">
          {getFileAttachmentName(fileAttachment)}
        </div>
      </Tooltip>
    </div>
  );
}

export default ItemCreateMessageAttachmentsListFile;
