import React from 'react';

import {
  ItemMessagesListMessageRepliedMessageFileAttachmentAttachment,
  ItemMessagesListMessageRepliedMessageSelectedProductAttachment,
  ItemMessagesListMessageRepliedMessageSelectedLifestyleAttachment,
  ItemMessagesListMessageRepliedMessageSelectedMaterialAttachment
} from './ItemMessagesListMessageRepliedMessageImage.types';

import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../../../messagesTypes';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';

import { Files } from '../../../../../../../../../utils/Files';
import { getFileAttachmentName } from '../../../../../../../../fileAttachments/utils/getFileAttachmentName';

const imageClassName =
  'w-full h-full rounded object-cover bg-gray-100 dark:bg-gray-850 break-words';

const linkClassName =
  'h-24 relative rounded bg-gray-100 dark:bg-gray-850 group/item w-24';

interface ItemMessagesListMessageRepliedMessageImageProps {
  attachment:
    | ItemMessagesListMessageRepliedMessageFileAttachmentAttachment
    | ItemMessagesListMessageRepliedMessageSelectedProductAttachment
    | ItemMessagesListMessageRepliedMessageSelectedLifestyleAttachment
    | ItemMessagesListMessageRepliedMessageSelectedMaterialAttachment;
  hiddenFilesCount?: number;
}

function ItemMessagesListMessageRepliedMessageImage({
  attachment,
  hiddenFilesCount
}: ItemMessagesListMessageRepliedMessageImageProps) {
  if (
    (attachment.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
      attachment.type === MessageFinAttachmentTypes.FILE_ATTACHMENT) &&
    Files.isImage(attachment.file)
  ) {
    return (
      <div className={linkClassName}>
        <ImageHelper
          className={imageClassName}
          src={attachment.file}
          alt={getFileAttachmentName(attachment)}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
          tooltipSingleton
        />
        {(attachment.drawing || attachment.reference || attachment.texture) && (
          <div className="absolute bottom-0 left-0 p-1 bg-gray-600 text-white flex rounded-bl rounded-tr">
            <div className="text-sm w-5 h-max text-center">
              {attachment.drawing ? <span>D</span> : null}
              {attachment.reference ? <span>R</span> : null}
              {attachment.texture ? <span>T</span> : null}
            </div>
          </div>
        )}

        {hiddenFilesCount && (
          <div className="absolute inset-0 flex items-center justify-center rounded object-cover bg-opacity-80 dark:bg-opacity-80 bg-white dark:bg-gray-900 text-lg font-medium">
            +{hiddenFilesCount}
          </div>
        )}
      </div>
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_PRODUCT) &&
    attachment.product &&
    attachment.product.image
  ) {
    return (
      <div className={linkClassName}>
        <ImageHelper
          className={imageClassName}
          src={attachment.product.image.file}
          alt={`${attachment.product.brand?.localizedName} ${attachment.product.name}`}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipSingleton
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        <div className="absolute bottom-0 left-0 p-1 bg-indigo-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.CUBE_OUTLINE} />
        </div>

        {hiddenFilesCount && (
          <div className="absolute inset-0 flex items-center justify-center rounded object-cover bg-opacity-80 dark:bg-opacity-80 bg-white dark:bg-gray-900 text-lg font-medium">
            +{hiddenFilesCount}
          </div>
        )}
      </div>
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE) &&
    attachment.lifestyle &&
    attachment.lifestyle.image
  ) {
    return (
      <div className={linkClassName}>
        <ImageHelper
          className={imageClassName}
          src={attachment.lifestyle.image.file}
          alt={attachment.lifestyle.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipSingleton
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        <div className="absolute bottom-0 left-0 p-1 bg-pink-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.RULERS_OUTLINE} className="w-5 h-5" />
        </div>

        {hiddenFilesCount && (
          <div className="absolute inset-0 flex items-center justify-center rounded object-cover bg-opacity-80 dark:bg-opacity-80 bg-white dark:bg-gray-900 text-lg font-medium">
            +{hiddenFilesCount}
          </div>
        )}
      </div>
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_MATERIAL) &&
    attachment.material &&
    attachment.material.image
  ) {
    return (
      <div className={linkClassName}>
        <ImageHelper
          tooltipAlt
          tooltipSingleton
          className={imageClassName}
          src={attachment.material.image.file}
          alt={attachment.material.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
        />
        <div className="absolute bottom-0 left-0 p-1 bg-teal-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.MATERIAL_OUTLINE} className="w-5 h-5" />
        </div>

        {hiddenFilesCount && (
          <div className="absolute inset-0 flex items-center justify-center rounded object-cover bg-opacity-80 dark:bg-opacity-80 bg-white dark:bg-gray-900 text-lg font-medium">
            +{hiddenFilesCount}
          </div>
        )}
      </div>
    );
  }

  return null;
}

export default ItemMessagesListMessageRepliedMessageImage;
